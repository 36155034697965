var exports = {};
function DefaultRequest(options) {
  this._url = options.url;
  this._data = options.data;
  this._method = options.method;
  this._headers = options.headers;
}
DefaultRequest.prototype.getUrl = function () {
  return this._url;
};
DefaultRequest.prototype.getBody = function () {
  return this._data;
};
DefaultRequest.prototype.getMethod = function () {
  return this._method;
};
DefaultRequest.prototype.getHeaders = function () {
  return this._headers;
};
DefaultRequest.prototype.adaptResponseBody = function (parsedBody) {
  return parsedBody;
};
DefaultRequest.prototype.determineStatus = function (status) {
  return status;
};
exports = DefaultRequest;
export default exports;