import _once from "../../lib/once";
import _ajaxDriver from "./ajax-driver";
var exports = {};
var once = _once;
var AJAXDriver = _ajaxDriver;
exports = function (options, cb) {
  cb = once(cb || Function.prototype);
  options.method = (options.method || "GET").toUpperCase();
  options.timeout = options.timeout == null ? 60000 : options.timeout;
  options.data = options.data || {};
  AJAXDriver.request(options, cb);
};
export default exports;